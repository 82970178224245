<template>
  <div class="box">
    <div class="box-body" style="height: 42rem;">
      <div v-if="chartOptions && !isProcessing && chartData.length > 0">
        <Highcharts :options="chartOptions" />
      </div>
      <div v-if="isProcessing" style="height: 42rem; display: flex; align-items: center; justify-content: center;">
        <i class="fa fa-spinner fa-4x fa-spin"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { genComponent } from "vue-highcharts";
import { mapGetters } from "vuex";

export default {
  name: "TradeChart",
  components: {
    Highcharts: genComponent("Highcharts", Highcharts),
  },
  props: {
    merchant: {
      type: Object,
      default: null,
    },
    botID: {
      type: Number,
      default: null,
    },
    sys: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isProcessing: false,
      chartData: [],
      chartOptions: null,
      symbol: "",
      bot: null,
      data: [],
      zoneLabels: ["Ticker", "Buy", "Sell"],
      interval: null,
    };
  },
  computed: {
    ...mapGetters([
      "symbols",
      "currentUser",
    ]),
  },
  watch: {
    botID: {
      handler(val) {
        if (val) {
          this.fetchChartData(val);
        }
      },
      immediate: true,
    },
    sys(val) {
      if (val) {
        this.bot = null;
        this.symbol = val;
        this.fetchChartData(null);
      }
    },
  },
  methods: {
    fetchChartData(botID = null) {
      this.isProcessing = true;
      const zonesIndex = {
        buy: 1,
        sell: 2,
        skipped: 0,
      };
      if (botID) {
        const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
        this.$http.get(`${apiBaseUrl}/TradeBot/chart`, {
          params: {
            MerchantID: this.merchant.ID,
            APIKey: this.merchant.APIKey,
            key: "",
            BotID: botID,
          },
        }).then(response => {
          this.isProcessing = false;
          this.data = [];
          const zones = [];
          const bot = response.data.data;
          this.bot = bot;
          if (bot.chartData.length) {
            bot.chartData.forEach(r => {
              this.data.push({
                x: new Date(r.CreatedOn).getTime(),
                y: r.CalculatedPrice,
                zoneIndex: zonesIndex[r.Status === "fulfilled" ? r.Action : r.Status],
              });
            });
            const zoneColors = ["#0ea5e9", "#16a34a", "#dc2626"];

            let currentZoneIndex = this.data[0].zoneIndex;
            for (let i = 1; i < this.data.length; i++) {
              if (this.data[i].zoneIndex !== currentZoneIndex) {
                zones.push({
                  value: this.data[i].x,
                  color: zoneColors[currentZoneIndex],
                });
                currentZoneIndex = this.data[i].zoneIndex;
              }
            }
            // Add the last zone
            zones.push({
              color: zoneColors[currentZoneIndex],
            });
          }

          const series = [{
            name: "Bought / Sold / Ticker",
            data: this.data ? this.data.map(point => [point.x, point.y]) : [],
            turboThreshold: 0,
            zones,
            zoneAxis: "x",
          }];
          this.chartData = series;
          this.updateChart(this.chartData);
        }).catch(_error => {
          this.isProcessing = false;
        });
      } else {
        const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
        this.$http.get(`${apiBaseUrl}/TradeBot/candles`, {
          params: {
            MerchantID: this.currentUser.ID,
            APIKey: this.currentUser.APIKey,
            Symbol: this.symbol,
          },
        }).then(response => {
          this.isProcessing = false;
          const data = [];
          data.push({
            type: "spline",
            name: this.symbol,
            data: response.data.data.map(r => {
              return [
                Number(r.time) * 1000,
                Number(r.closingPrice),
              ];
            }),
          });
          this.chartData = data;
          this.updateChart(this.chartData);
        }).catch(error => {
          this.isProcessing = false;
          console.log(error.response);
        });
      }
    },
    updateChart(data) {
      this.chartOptions = null;
      this.chartOptions = {
        type: "spline",
        title: {
          text: this.botID && this.bot ? "Price Ticker Last 30 days - Bot ID: " + this.botID + " - Market: " + this.bot.bot.Symbol : "1 " + this.symbol.split("-")[0],
        },
        chart: {
          zoomType: "xy",
          backgroundColor: "transparent",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          type: "datetime",
          title: {
            text: "",
          },
        },
        yAxis: {
          title: {
            text: this.botID ? "Price" : "Currency Amount",
          },
          labels: {
            // eslint-disable-next-line space-before-function-paren
            formatter: function () {
              return this.value.toFixed(8);
            },
          },
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
          zones: [{
            value: 1, // Apply the first zone style to all points with y <= 0.5
            color: "#16a34a", // Buy zone color
          }, {
            value: 2, // Apply the second zone style to points with 0.5 < y <= 1.5
            color: "#dc2626", // Sell zone color
          }, {
            value: 0,
            color: "#0ea5e9", // Apply the third zone style to points with y > 1.5
          }],
        },
        tooltip: {
          // eslint-disable-next-line space-before-function-paren
          formatter: function () {
            const point = this.points ? this.points[0] : this.point;
            if (this.botID && this.bot) {
              const index = this.data.findIndex(d => d.x === point.x && d.y === point.y);
              const zoneIndex = this.data[index].zoneIndex;
              const zoneLabel = this.zoneLabels[zoneIndex];
              return `<b>${zoneLabel}</b><br/>Price: ${point.y}`;
            }
            return `Price: ${point.y.toFixed(8)}`;
          },
          shared: true,
        },
        series: data,
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      };
    },
  },
  beforeMount() {
    if (this.symbols) {
      this.symbol = "BTC-EUR";
      this.fetchChartData(null);
    }
    this.interval = setInterval(() => {
      this.fetchChartData(this.botID || null);
    }, 60000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
};
</script>
