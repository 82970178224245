<template>
  <div v-if="!isLoading">
    <div v-if="bot && bot.audits">
      <TradeChart :data="bot.audits" />
    </div>
    <div class="table-responsive" v-if="bot && bot.bot">
      <table class="table table-condensed table-striped">
        <thead>
          <tr>
            <th>Bot ID</th>
            <th>Merchant</th>
            <th>Pair</th>
            <th>Algo</th>
            <th>Invested {{ getCurrency(bot.bot.Symbol, 0) }}</th>
            <th>Invested {{ getCurrency(bot.bot.Symbol, 1) }}</th>
            <th>Profit Target</th>
            <th>Actual Target</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ bot.bot.ID }}</td>
            <td>{{ bot.bot.MerchantName }}</td>
            <td>{{ bot.bot.Symbol }}</td>
            <td>{{ bot.bot.Algo }}</td>
            <td>{{ bot.bot.InitialBaseAmount }}</td>
            <td>{{ bot.bot.InitialQuoteAmount }}</td>
            <td>{{ bot.bot.ProfitPercentage + '%' }}</td>
            <td>
              <span v-if="bot.bot.LastBid">{{ calculateProfitActual(bot.bot) }}%</span>
            </td>
            <td class="no-wrap">
              <span>{{ bot.bot.Status }}</span>
              <button class="btn btn-danger btn-flat btn-sm ml-8" v-if="bot.bot.Status === 'running'"
                @click="cancelBot(bot.bot)">
                Cancel
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import TradeChart from "@/components/tradebot/Charts/TradeChart";
export default {
  name: "BotDetail",
  components: {
    TradeChart,
  },
  data() {
    return {
      id: null,
      bot: null,
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "jwtToken",
    ]),
  },
  methods: {
    calculateProfitActual(bot) {
      const initial = (Number(bot.InitialBaseAmount) * Number(bot.Price)) + Number(bot.InitialQuoteAmount);
      const final = (Number(bot.FinalBaseAmount) * Number(bot.LastBid)) + Number(bot.FinalQuoteAmount);
      return (100 * ((final - initial) / ((initial + final) / 2))).toFixed(2);
    },
    getCurrency(symbol, index) {
      return symbol.split("-")[index];
    },
    fetchBotDetail(isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=BotDetail`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          botID: this.id,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        this.bot = response.data.data;
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.$toast.fire("", error.data.message, "error");
      });
    },
    cancelBot(bot) {
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$swal.fire({
        title: "Cancel Bot",
        text: "Are you sure you want to cancel this bot?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.$http.get(`${apiBaseUrl}/TradeBot/bot/cancel`, {
            params: {
              BotID: bot.ID,
              MerchantID: bot.MerchantID,
              APIKey: bot.APIKey,
              key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
            },
          }).then(response => {
            // update status
            if (response.status === 200) {
              this.bot.bot.Status = "Cancelled";
            }
          }).catch(_error => {
          });
        }
      });
    },
  },
  mounted() {
    if (Object.keys(this.$route.params).length > 0 && this.$route.params?.id) {
      this.id = this.$route.params.id;
      this.fetchBotDetail();
    } else {
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>
